<template>
  <div class="my-10 mevcut-container text-center">
    <div class="text-xl mb-5 text-nav_color font-bold">
      {{ t('successful_purchase_cart') }}
    </div>
    <div class="mb-8 text-lg">
      <div class="flex flex-wrap justify-center">
        <div class="font-semibold mx-0.5">
          {{
            t('payment_completed_successfully', {
              nameStore: order?.store?.name
            })
          }}
        </div>
      </div>
      <MevcutLink
        class="flex-shrink-0"
        :aria-label="t('aria_page_label', { page: t('orders') })"
        :to="`/my-account/orders/${route.query.bill_id}`"
      >
        <div class="flex justify-center mt-2">
          <div class="text-nav_color">{{ t('view_bill') }}</div>

          <div class="text-nav_color mx-2 font-medium">
            {{ t('click_here') }}
          </div>
        </div>
      </MevcutLink>

      <div class="h-5"></div>
      <MevcutLink
        to="/cart"
        class="bg-nav_color text-text_alt_color text-sm rounded py-1 px-2"
        :aria-label="t('aria_page_label', { page: t('cart') })"
      >
        {{ t('browse', { page: t('cart') }) }}
      </MevcutLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import type { Store } from '~~/composables/useMenuModel'
const { url } = useDomainHost()
const { t } = useI18n()
const route = useRoute()
const { fetchBill } = useBill()
const { storeModel } = useMenuModel()
const cart = useCartStore()
const order = ref<UserOrder | null>(null)

if (route.query.bill_id) {
  const { data: orderTemp } = await fetchBill(route.query.bill_id.toString())

  if (orderTemp.value) {
    order.value = orderTemp.value!
  }
  //   const nearByStores = ref<Store[] | undefined>(undefined)
  // await fetchNearByStores(route.query.bill_id.toString())
}

// const otherStoresInCart = computed(() => {
//   if (order.value?.store_id) {
//     return cart.itemsByStoreSortedByOffer.filter(
//       (itemStore: ItemStoreCart) => itemStore.id !== order.value?.store_id
//     )
//   }
//   return cart.itemsByStoreSortedByOffer
// })

// async function fetchNearByStores(billId?: string) {
//   const { data } = await useBasicFetch(
//     getAllRoutes().storesRoutes.getNearbyStores,
//     {
//       query: {
//         limit: 100,
//         only_nearBy: 1,
//         bill_id: billId
//       }
//     }
//   )
//   if (data.value?.data?.stores?.length) {
//     nearByStores.value = data.value?.data?.stores?.map(storeModel)
//   }
// }
useServerSeoMeta(
  useOgMeta(
    url + '/successful-purchase-cart',
    t('successful_purchase_cart'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('successful_purchase_cart'),
  link: [useSeoCanonical(url + '/successful-purchase-cart')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(
        2,
        t('successful_purchase_cart'),
        url + '/successful-purchase-cart'
      )
    ])
  ]
})
</script>
